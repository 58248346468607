import './App.css'
import React from 'react'
import Header from './components/Header/Header'
import Measures from './components/Measures/Measures'
import Note from './components/Note/Note'
import Footer from './components/Footer/Footer'


const App = () => {

    return (
        <div className="contentDiv">
            <Header />
            <Measures />
            <Note />
            <Footer />
        </div>
    )
}

export default App