import '../../components/Note/Note.css'
import React from 'react'
import TwoBeamedSixteenthNotes from './TwoBeamedSixteenthNotes'

const StackTwoBeamedSixteenthNotes = () => {

    return (
        <div className="twoBeamedSixteenth noteStack">
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
            <TwoBeamedSixteenthNotes />
        </div>
    )
}

export default StackTwoBeamedSixteenthNotes