import '../../components/Note/Note.css'
import React from 'react'
import HalfNote from '../Notes/HalfNote';

const StackHalfNote = () => {

    return (
        <div className="half noteStack">
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
            <HalfNote />
        </div>
    )
}

export default StackHalfNote


