import React from 'react'
import DottedQuarterNote from './DottedQuarterNote'

const StackDottedQuarterNote = () => {

    return (
        <>
            <div className="dottedQuarter noteStack">
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
                <DottedQuarterNote />
            </div>
        </>
    )
}

export default StackDottedQuarterNote