import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import halfNoteBox from '../../assets/halfNoteBox.png'

const HalfNote = () => {

    return (
        <Draggable>
            <img 
                className="note half" 
                src={halfNoteBox} 
                alt="half note" 
            />
        </Draggable>
    )
}

export default HalfNote
