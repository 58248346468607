import '../../components/Note/Note.css'
import React from 'react'
import DottedEighthNote from './DottedEighthNote'

const StackDottedEighthNote = () => {

    return (
        <div className="dottedEighth noteStack">
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
            <DottedEighthNote />
        </div>
    )
}

export default StackDottedEighthNote