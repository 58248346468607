import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import sixteenthNoteBox from '../../assets/sixteenthNoteBox.png'

const SixteenthNote = () => {

    return (
        <Draggable>
            <img 
                className="note sixteenth" 
                src={sixteenthNoteBox} 
                alt="sixteenth note" 
            />
        </Draggable>
    )
}

export default SixteenthNote
