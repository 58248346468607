import './FourFour.css'
import React from 'react'
import fourFourBox from '../../assets/fourFourBox.png'

export default function FourFour() {
    return (
        <div>
            <img className="fourFour" src={fourFourBox} alt="four four time" />
        </div>
    )
}