import '../../components/Note/Note.css'
import React from 'react'
import EighthNote from './EighthNote'

const StackEighthNote = () => {

    return (
        <div className="eighth noteStack">
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
            <EighthNote />
        </div>
    )
}

export default StackEighthNote