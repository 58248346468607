import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import wholeNoteBox from '../../assets/wholeNoteBox.png'

const WholeNote = () => {

    return (
        <Draggable>
            <img 
                className="note whole" 
                src={wholeNoteBox} 
                alt="whole note" 
            />
        </Draggable>
    )
}

export default WholeNote