import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import fourBeamedSixteenthNotesBox from '../../assets/fourBeamedSixteenthNotesBox.png'

const FourBeamedSixteenthNotes = () => {

    return (
        <Draggable>
            <img 
                className="note fourBeamedSixteenth" 
                src={fourBeamedSixteenthNotesBox} 
                alt="four beamed sixteenth notes" 
            />
        </Draggable>
    )
}

export default FourBeamedSixteenthNotes

