import '../../components/Note/Note.css'
import React from 'react'
import DottedHalfNote from './DottedHalfNote'

const StackDottedHalfNote = () => {

    return (
        <>
            <div className="dottedHalf noteStack">
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
                <DottedHalfNote />
            </div>
        </>
    )
}

export default StackDottedHalfNote