import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import twoBeamedEighthNotesBox from '../../assets/twoBeamedEighthNotesBox.png'

const TwoBeamedEighthNotes = () => {

    return (
        <Draggable>
            <img 
                className="note twoBeamedEighth" 
                src={twoBeamedEighthNotesBox} 
                alt="two beamed eighth notes" 
            />
        </Draggable>
    )
}

export default TwoBeamedEighthNotes

