import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import eighthNoteBox from '../../assets/eighthNoteBox.png'

const EighthNote = () => {

    return (
        <Draggable>
            <img 
                className="note eighth" 
                src={eighthNoteBox} 
                alt="eighth note" 
            />
        </Draggable>
    )
}

export default EighthNote