import '../../components/Note/Note.css'
import React from 'react'
import TwoBeamedEighthNotes from './TwoBeamedEighthNotes'

const StackTwoBeamedEighthNotes = () => {

    return (
        <div className="twoBeamedEighth noteStack">
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
            <TwoBeamedEighthNotes />
        </div>
    )
}

export default StackTwoBeamedEighthNotes