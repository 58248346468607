import './Measures.css'
import React from 'react'
import SimpleQuad from '../SimpleQuad'
import FourFour from '../FourFour/FourFour'

export default function Measures() {
    return (
        <div>
            <div className="measures">
                <div className="firstMeasureWrapper">
                    <div className="firstMeasureMeter">
                        <FourFour />
                    </div>
                    <div className="firstMeasureDiv">
                        <SimpleQuad />
                    </div>
                </div>
                <div className="secondMeasureWrapper">
                    <div className="secondMeasureMeter">
                        <FourFour />
                    </div>
                    <div className="secondMeasureDiv">
                        <SimpleQuad />
                    </div>
                </div>
            </div>
        </div>
    )
}