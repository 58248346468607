import '../../components/Note/Note.css'
import React from 'react'
import FourBeamedSixteenthNotes from './FourBeamedSixteenthNotes'

const StackFourBeamedSixteenthNotes = () => {

    return (
        <div className="fourBeamedSixteenth noteStack">
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
            <FourBeamedSixteenthNotes />
        </div>
    )
}

export default StackFourBeamedSixteenthNotes