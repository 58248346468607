import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import dottedQuarterNoteBox from '../../assets/dottedQuarterNoteBox.png'

const DottedQuarterNote = () => {

    return (
        <Draggable>
            <img 
                className="note dottedQuarter" 
                src={dottedQuarterNoteBox} 
                alt="dotted quarter note" 
            />
        </Draggable>
    )
}

export default DottedQuarterNote
