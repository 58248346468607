import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import twoBeamedSixteenthNotesBox from '../../assets/twoBeamedSixteenthNotesBox.png'

const TwoBeamedSixteenthNotes = () => {

    return (
        <Draggable>
            <img 
                className="note twoBeamedSixteenth" 
                src={twoBeamedSixteenthNotesBox} 
                alt="two beamed sixteenth notes" 
            />
        </Draggable>
    )
}

export default TwoBeamedSixteenthNotes