import './Note.css'
import React from 'react'

import StackWholeNote from '../Notes/StackWholeNote';
import StackDottedHalfNote from '../Notes/StackDottedHalfNote';
import StackHalfNote from '../Notes/StackHalfNote';
import StackDottedQuarterNote from '../Notes/StackDottedQuarterNote';
import StackQuarterNote from '../Notes/StackQuarterNote';
import StackTwoBeamedEighthNotes from '../Notes/StackTwoBeamedEighthNotes';
import StackFourBeamedSixteenthNotes from '../Notes/StackFourBeamedSixteenthNotes';
import StackEighthNote from '../Notes/StackEighthNote';
import StackTwoBeamedSixteenthNotes from '../Notes/StackTwoBeamedSixteenthNotes';
import StackSixteenthNote from '../Notes/StackSixteenthNote';
import StackDottedEighthNote from '../Notes/StackDottedEighthNote';

const Note = () => {

    return (
        <div>
            <div className="noteBank">
            {/* Putting notes in descending order makes them stack correctly */}
                <StackWholeNote />
                <StackDottedHalfNote />
                <StackHalfNote />
                <StackDottedQuarterNote />
                <StackQuarterNote />
                <StackTwoBeamedEighthNotes />
                <StackFourBeamedSixteenthNotes />
                <StackDottedEighthNote />
                <StackEighthNote />
                <StackTwoBeamedSixteenthNotes />
                <StackSixteenthNote />
            </div>
        </div>
    )
}

export default Note
