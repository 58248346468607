import React from 'react'
import WholeNote from './WholeNote'

const StackWholeNote = () => {

    return (
        <div className="whole noteStack">
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
            <WholeNote />
        </div>
    )
}

export default StackWholeNote