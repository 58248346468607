import '../../components/Note/Note.css'
import React from 'react'
import SixteenthNote from './SixteenthNote'

const StackSixteenthNote = () => {

    return (
        <div className="sixteenth noteStack">
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
            <SixteenthNote />
        </div>
    )
}

export default StackSixteenthNote