import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import dottedHalfNoteBox from '../../assets/dottedHalfNoteBox.png'

const DottedHalfNote = () => {

    return (
        <Draggable>
            <img 
                className="note dottedHalf" 
                src={dottedHalfNoteBox} 
                alt="dotted half note" 
            />
        </Draggable>
    )
}

export default DottedHalfNote