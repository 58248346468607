import './Header.css'
import React from 'react'
import theoryRevolution from '../../assets/theoryRevolution.png'
import fiveBarMenuNotes from '../../assets/fiveBarMenuNotes.png'

export default function Header() {
    return (
        <header className="header">
            <img className="logo" src={theoryRevolution} alt="Theory Revolution" />
            <nav className="nav">
                <ul>
                    <li>About</li>
                    <li>Sign Up</li>
                    <li>Log In</li>
                </ul>
                <img className="menu" src={fiveBarMenuNotes} alt="menu icon" />
            </nav>
        </header>
    )
}