import '../../components/Note/Note.css'
import React from 'react'
import Draggable from '../Draggable';

import dottedEighthNoteBox from '../../assets/dottedEighthNoteBox.png'

const DottedEighthNote = () => {

    return (
        <Draggable>
            <img 
                className="note dottedEighth" 
                src={dottedEighthNoteBox} 
                alt="dotted eighth note" 
            />
        </Draggable>
    )
}

export default DottedEighthNote