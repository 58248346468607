import '../../components/Note/Note.css'
import React from 'react'
import QuarterNote from './QuarterNote'

const StackQuarterNote = () => {

    return (
        <div className="quarter noteStack">
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
            <QuarterNote />
        </div>
    )
}

export default StackQuarterNote